@use '../utils/' as u;

:root {
    --ff-inter: 'Inter', sans-serif;

    --fs-h1: 2.25rem;
    --fs-h2: 1.75rem;
    --fs-h3: 1.5rem;
    --fs-h4: 1.25rem;
    --fs-body: 1rem;
    --fs-small: .875rem;

    --fw-bold: 700;
    --fw-medium: 500;
    --fw-regular: 400;
}

@include u.breakpoint-up(lg) {
    :root {
        --fs-h1: 2.5rem;
        --fs-h2: 1.875rem;
        --fs-h3: 1.625rem;
        --fs-h4: 1.325rem;
        --fs-body: 1.125rem;
        --fs-small: .925rem
    }
}
