@use '../utils/' as u; 

.home {
    color: hsl(var(--clr-white));
    background-color: hsla(var(--clr-black), .5);
    background-image: url('../images/homebg.jpg'), linear-gradient(to right, hsla(var(--clr-black), .7) 40%, hsl(var(--clr-white)));
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
}

.intro {
    padding: 10em 0 7em;
    text-align: center;

    &__greeting {
        font-size: var(--fs-h4);
    }

    &__name {
        margin-bottom: .5em;
        @include u.animate-str(15)
    }

    &__job-desc {
        font-size: var(--fs-h3);
        font-weight: var(--fw-regular);
        margin-bottom: 2.5em;
    }

    &__dl-btn {
        position: relative;
        font-family: var(--ff-inter);
        font-size: var(--fs-small);
        font-weight: var(--fw-medium);
        text-transform: uppercase;
        color: hsl(var(--clr-white));
        background-color: hsla(var(--clr-white), .25);
        border: 1px solid hsl(var(--clr-white));
        padding: .75em 2em;
        z-index: 1;
        cursor: pointer;
        @include u.transition-ease-in-out;
        animation: fadeInY 1s ease-in-out forwards;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            background-color: hsl(var(--clr-accent));
            transform: scale(0, 1);
            @include u.transition-ease-in-out;
        }

        &:hover {
            color: hsl(var(--clr-black));
            
            &::before {
                transform: scale(1, 1);
            }
        }
    }
    
    @include u.breakpoint-up(md) {
        text-align: left;
    }
}