@use '../utils/' as u;

.about {
    .container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1em;
        grid-template-areas: 
            "card"
            "title"
            "desc"
            "secondary";
        padding: 6em 0;
        overflow: hidden;

        @include u.breakpoint-up(sm) {
            .card {
                width: 90%;
                margin-right: 1em;

            }
        }

        @include u.breakpoint-up(md) {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
            "card title"
            "card desc";
    
            .card {
                margin-right: 5em;
            }
        }

        @include u.breakpoint-up(lg) {
            grid-template-columns: 3fr 4fr;
        }
    }
}

.card {
    grid-area: card;
    margin: 0 auto;
    box-shadow: -20px 20px 20px -20px hsl(var(--clr-black));
    transform: translateX(1em);

    &__photo {
        min-height: 300px;
        object-fit: cover;
        object-position: left;
    }

    &__social-icons {
        grid-area: icons;
        font-size: 1.5em;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 1em;
        background-color: hsla(var(--clr-accent), 1);
        color: hsl(var(--clr-black));
    }
    
    .icon {
        height: 1em;
        width: 1em;
        padding: .5em;
        border-radius: 50%;
        cursor: pointer;
        opacity: 0;

        &.animate-el {
            animation: fadeInY 1s ease-in-out forwards;
        }

        &:hover {
            color: hsl(var(--clr-white));
            background-color: hsla(var(--clr-black), .75);
            @include u.transition-ease-in-out;
        }
    }
}   

.title {
    grid-area: title;
    font-weight: var(--fw-medium);
    margin-top: 1em;
    @include u.animate-str(8);
}

.desc {
    grid-area: desc;
    text-align: justify;
    opacity: 0;

    &.animate-el {
        animation: fadeInY 1s ease-in-out forwards;
    }
} 