:root {
  --ff-inter: "Inter", sans-serif;
  --fs-h1: 2.25rem;
  --fs-h2: 1.75rem;
  --fs-h3: 1.5rem;
  --fs-h4: 1.25rem;
  --fs-body: 1rem;
  --fs-small: .875rem;
  --fw-bold: 700;
  --fw-medium: 500;
  --fw-regular: 400;
}

@media (min-width: 960px) {
  :root {
    --fs-h1: 2.5rem;
    --fs-h2: 1.875rem;
    --fs-h3: 1.625rem;
    --fs-h4: 1.325rem;
    --fs-body: 1.125rem;
    --fs-small: .925rem ;
  }
}
:root {
  --clr-light: 0, 100%, 98%;
  --clr-dark: 212, 100%, 16%;
  --clr-primary: 188, 72%, 36%;
  --clr-accent: 72, 66%, 51%;
  --clr-black: 0, 0%, 0%;
  --clr-white: 0, 0%, 100%;
}

:root {
  --ligth: #fff5f5;
  --accent: #b2d430;
  --primary: #1a8b9d;
  --dark: #002651;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--ff-inter);
  font-size: var(--fs-body);
  font-weight: var(--fw-regular);
  color: hsl(var(--clr-black));
  background-color: hsl(var(--clr-white));
  line-height: 1.6;
}

img {
  display: block;
  max-width: 100%;
}

.container {
  width: min(95%, 1200px);
  margin: 0 auto;
}
@media (min-width: 720px) {
  .container {
    width: min(90%, 1200px);
  }
}

h1, h2, h3, h4 {
  margin-top: 0;
  font-weight: var(--fw-bold);
}

h1 {
  font-size: var(--fs-h1);
}

h2 {
  font-size: var(--fs-h2);
}

h3 {
  font-size: var(--fs-h3);
}

h4 {
  font-size: var(--fs-h4);
}

p {
  margin-top: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

a, a:active {
  text-decoration: none;
}

@-webkit-keyframes fadeInY {
  0% {
    opacity: 0;
    transform: translateY(3em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInY {
  0% {
    opacity: 0;
    transform: translateY(3em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInX {
  0% {
    opacity: 0;
    transform: translateX(3em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeInX {
  0% {
    opacity: 0;
    transform: translateX(3em);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes showItemsUp {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes showItemsUp {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes svgProgress {
  0% {
    stroke-dashoffset: 472;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes svgProgress {
  0% {
    stroke-dashoffset: 472;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes grow {
  0% {
    width: 0px;
    transform: scale(0);
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 25%;
    transform: scale(1);
  }
}
@keyframes grow {
  0% {
    width: 0px;
    transform: scale(0);
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 25%;
    transform: scale(1);
  }
}
@-webkit-keyframes shrink {
  0% {
    width: 25%;
    transform: scale(1);
  }
  75% {
    opacity: 0;
  }
  100% {
    width: 0px;
    transform: scale(0);
  }
}
@keyframes shrink {
  0% {
    width: 25%;
    transform: scale(1);
  }
  75% {
    opacity: 0;
  }
  100% {
    width: 0px;
    transform: scale(0);
  }
}
.header {
  background-color: hsla(var(--clr-light), 0.9);
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 1em 0;
  color: hsl(var(--clr-white));
  background-color: transparent;
  transition: all 400ms ease-in-out;
}
.header.inverseClass {
  --clr-black: 0, 0%, 100%;
  --clr-white: 0, 0%, 0%;
  background-color: hsla(var(--clr-black), 1);
  box-shadow: 0 5px 30px -15px hsla(var(--clr-white));
}
.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-btn {
  position: relative;
  width: 28px;
  height: 28px;
  display: none;
  cursor: pointer;
  z-index: 50;
}
@media (max-width: 720px) {
  .menu-btn {
    display: block;
  }
  .menu-btn__burger {
    position: absolute;
    right: 0;
    top: 12px;
    width: 28px;
    height: 3px;
    background-color: hsl(var(--clr-white));
    transition: all 400ms ease-in-out;
  }
  .menu-btn__burger::before {
    content: "";
    position: absolute;
    top: -8px;
    width: 28px;
    height: 3px;
    background-color: hsl(var(--clr-white));
    transition: all 400ms ease-in-out;
  }
  .menu-btn__burger::after {
    content: "";
    position: absolute;
    top: 8px;
    width: 20px;
    height: 3px;
    background-color: hsl(var(--clr-white));
    transition: all 400ms ease-in-out;
  }
  .menu-btn__burger.open {
    transform: rotate(720deg);
    background-color: transparent;
  }
  .menu-btn__burger.open::before {
    transform: rotate(45deg) translate(5px, 8px);
  }
  .menu-btn__burger.open::after {
    width: 28px;
    transform: rotate(-45deg) translate(3px, -7px);
  }
}

.menu {
  margin-right: -1em;
  font-size: var(--fs-small);
  font-weight: var(--fw-medium);
  text-transform: uppercase;
  transition: all 400ms ease-in-out;
}
@media (max-width: 720px) {
  .menu {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 20em;
    height: auto;
    overflow: hidden;
    background-color: hsla(var(--clr-black), 0.95);
    z-index: 20;
    transform: translateX(100%);
  }
  .menu__list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .menu__list-item {
    margin-left: 0;
    margin-bottom: 3em;
    transition: all 400ms ease-in-out;
  }
  .menu__list .link {
    display: inline-block;
    padding: 0.5em 1.25em;
    width: 8em;
    text-align: center;
  }
  .menu.open {
    transform: translateX(0);
  }
  .menu.open .menu__list-item {
    -webkit-animation: showItemsUp 500ms both;
            animation: showItemsUp 500ms both;
  }
  .menu.open .menu__list-item:nth-child(1) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .menu.open .menu__list-item:nth-child(2) {
    -webkit-animation-delay: 0.25s;
            animation-delay: 0.25s;
  }
  .menu.open .menu__list-item:nth-child(3) {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .menu.open .menu__list-item:nth-child(4) {
    -webkit-animation-delay: 0.35s;
            animation-delay: 0.35s;
  }
  .menu.open .menu__list-item:nth-child(5) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  .menu.open .menu__list-item:nth-child(6) {
    -webkit-animation-delay: 0.45s;
            animation-delay: 0.45s;
  }
  .menu.open .menu__list-item:nth-child(7) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
}

.menu__list {
  display: flex;
}
.menu__list-item {
  margin-left: 2em;
}
.menu__list .link {
  position: relative;
  padding: 0.325em 0.25em;
  cursor: pointer;
}
.menu__list .link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.1em;
  left: 0;
  bottom: 0;
  background-color: hsl(var(--clr-accent));
  transform: scale(0, 1);
  transform-origin: 0% 100%;
  transition: all 400ms ease-in-out;
}
.menu__list .link:hover::after, .menu__list .link:focus::after {
  transform: scale(1, 1);
}
.menu__list .link.active::after {
  transform: scale(1, 1);
}

.home {
  color: hsl(var(--clr-white));
  background-color: hsla(var(--clr-black), 0.5);
  background-image: url("../images/homebg.jpg"), linear-gradient(to right, hsla(var(--clr-black), 0.7) 40%, hsl(var(--clr-white)));
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
}

.intro {
  padding: 10em 0 7em;
  text-align: center;
}
.intro__greeting {
  font-size: var(--fs-h4);
}
.intro__name {
  margin-bottom: 0.5em;
}
.intro__name .animate-char {
  white-space: pre;
  display: inline-block;
  -webkit-animation: fadeInX 1s backwards;
          animation: fadeInX 1s backwards;
}
.intro__name .animate-char._0 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.intro__name .animate-char._1 {
  -webkit-animation-delay: 0.05s;
          animation-delay: 0.05s;
}
.intro__name .animate-char._2 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.intro__name .animate-char._3 {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}
.intro__name .animate-char._4 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.intro__name .animate-char._5 {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.intro__name .animate-char._6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.intro__name .animate-char._7 {
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
}
.intro__name .animate-char._8 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.intro__name .animate-char._9 {
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}
.intro__name .animate-char._10 {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.intro__name .animate-char._11 {
  -webkit-animation-delay: 0.55s;
          animation-delay: 0.55s;
}
.intro__name .animate-char._12 {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.intro__name .animate-char._13 {
  -webkit-animation-delay: 0.65s;
          animation-delay: 0.65s;
}
.intro__name .animate-char._14 {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.intro__job-desc {
  font-size: var(--fs-h3);
  font-weight: var(--fw-regular);
  margin-bottom: 2.5em;
}
.intro__dl-btn {
  position: relative;
  font-family: var(--ff-inter);
  font-size: var(--fs-small);
  font-weight: var(--fw-medium);
  text-transform: uppercase;
  color: hsl(var(--clr-white));
  background-color: hsla(var(--clr-white), 0.25);
  border: 1px solid hsl(var(--clr-white));
  padding: 0.75em 2em;
  z-index: 1;
  cursor: pointer;
  transition: all 400ms ease-in-out;
  -webkit-animation: fadeInY 1s ease-in-out forwards;
          animation: fadeInY 1s ease-in-out forwards;
}
.intro__dl-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: hsl(var(--clr-accent));
  transform: scale(0, 1);
  transition: all 400ms ease-in-out;
}
.intro__dl-btn:hover {
  color: hsl(var(--clr-black));
}
.intro__dl-btn:hover::before {
  transform: scale(1, 1);
}
@media (min-width: 720px) {
  .intro {
    text-align: left;
  }
}

.about .container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  grid-template-areas: "card" "title" "desc" "secondary";
  padding: 6em 0;
  overflow: hidden;
}
@media (min-width: 480px) {
  .about .container .card {
    width: 90%;
    margin-right: 1em;
  }
}
@media (min-width: 720px) {
  .about .container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "card title" "card desc";
  }
  .about .container .card {
    margin-right: 5em;
  }
}
@media (min-width: 960px) {
  .about .container {
    grid-template-columns: 3fr 4fr;
  }
}

.card {
  grid-area: card;
  margin: 0 auto;
  box-shadow: -20px 20px 20px -20px hsl(var(--clr-black));
  transform: translateX(1em);
}
.card__photo {
  min-height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: left;
     object-position: left;
}
.card__social-icons {
  grid-area: icons;
  font-size: 1.5em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1em;
  background-color: hsla(var(--clr-accent), 1);
  color: hsl(var(--clr-black));
}
.card .icon {
  height: 1em;
  width: 1em;
  padding: 0.5em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
}
.card .icon.animate-el {
  -webkit-animation: fadeInY 1s ease-in-out forwards;
          animation: fadeInY 1s ease-in-out forwards;
}
.card .icon:hover {
  color: hsl(var(--clr-white));
  background-color: hsla(var(--clr-black), 0.75);
  transition: all 400ms ease-in-out;
}

.title {
  grid-area: title;
  font-weight: var(--fw-medium);
  margin-top: 1em;
}
.title .animate-char {
  white-space: pre;
  display: inline-block;
  -webkit-animation: fadeInX 1s backwards;
          animation: fadeInX 1s backwards;
}
.title .animate-char._0 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.title .animate-char._1 {
  -webkit-animation-delay: 0.05s;
          animation-delay: 0.05s;
}
.title .animate-char._2 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.title .animate-char._3 {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}
.title .animate-char._4 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.title .animate-char._5 {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.title .animate-char._6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.title .animate-char._7 {
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
}

.desc {
  grid-area: desc;
  text-align: justify;
  opacity: 0;
}
.desc.animate-el {
  -webkit-animation: fadeInY 1s ease-in-out forwards;
          animation: fadeInY 1s ease-in-out forwards;
}

.skills .container {
  padding: 2em 0;
}
.skills .title {
  text-align: center;
  font-weight: var(--fw-medium);
  margin-top: 1em;
}
.skills .title .animate-char {
  white-space: pre;
  display: inline-block;
  -webkit-animation: fadeInX 1s backwards;
          animation: fadeInX 1s backwards;
}
.skills .title .animate-char._0 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.skills .title .animate-char._1 {
  -webkit-animation-delay: 0.05s;
          animation-delay: 0.05s;
}
.skills .title .animate-char._2 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.skills .title .animate-char._3 {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}
.skills .title .animate-char._4 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.skills .title .animate-char._5 {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}
.skills .title .animate-char._6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.skills .title .animate-char._7 {
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
}
.skills .tabs__bloc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.skills .tabs__bloc-btn {
  background-color: transparent;
  border: none;
  text-align: center;
  width: 100%;
  padding: 1em 0;
  cursor: pointer;
  position: relative;
}
.skills .tabs__bloc-btn .btn-text {
  margin-bottom: 0;
}
.skills .tabs__bloc-btn:hover {
  background-color: hsl(var(--clr-white));
}
.skills .tabs__bloc-btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 0.25em;
  background-color: hsl(var(--clr-accent));
  transform: scale(0, 1);
  transition: all 400ms ease-in-out;
}
.skills .tabs__bloc-btn.active {
  background-color: hsl(var(--clr-white));
}
.skills .tabs__bloc-btn.active::after {
  transform: scale(1, 1);
}
.skills .tabs__contents {
  background-color: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4em 0;
}
.skills .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 400ms ease-in-out;
  margin-bottom: 2em;
}
.skills .item.grow {
  -webkit-animation: grow 1s both;
          animation: grow 1s both;
}
.skills .item.shrink {
  -webkit-animation: shrink 1s forwards;
          animation: shrink 1s forwards;
}
.skills .item__name {
  margin-bottom: 0;
}
.skills .item__brand-icon {
  height: 1em;
  width: 1em;
  font-size: 2em;
  color: hsl(1deg, 77%, 55%);
  background-color: hsla(var(--clr-black, ), 0.5);
  padding: 0.25em;
  border-radius: 50%;
  margin-bottom: 0.25em;
}
.skills .item__rate {
  width: 80px;
  height: 80px;
  position: relative;
}
.skills .item__rate .outer {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 6px 6px 10px -1px hsl(var(--clr-black)), -6px -6px 10px -1px hsl(var(--clr-white));
  display: flex;
  justify-content: center;
  align-items: center;
}
.skills .item__rate .inner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: inset 4px 4px 6px -1px hsl(var(--clr-black)), inset -4px -4px 6px -1px hsl(var(--clr-white));
  display: flex;
  justify-content: center;
  align-items: center;
}
.skills .item__rate-svg {
  position: absolute;
  top: 0;
  left: 0;
}
.skills .item__rate-svg circle {
  fill: none;
  stroke: hsl(var(--clr-accent));
  stroke-width: 10px;
  stroke-dasharray: 472;
  stroke-dashoffset: 472;
  -webkit-animation: svgProgress 1s linear forwards;
          animation: svgProgress 1s linear forwards;
}/*# sourceMappingURL=styles.css.map */