@use '../utils/' as u;

.header {
    background-color: hsla(var(--clr-light), .9);
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 1em 0;
    color: hsl(var(--clr-white));
    background-color: transparent;
    @include u.transition-ease-in-out;

    &.inverseClass {
        --clr-black: 0, 0%, 100%;
        --clr-white: 0, 0%, 0%;
        background-color: hsla(var(--clr-black), 1);
        box-shadow: 0 5px 30px -15px hsla(var(--clr-white));
    }

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.menu-btn {
    position: relative;
    width: 28px;
    height: 28px;
    display: none;
    cursor: pointer;
    z-index: 50;

    @include u.breakpoint-down(md) {
        display: block;
        
        &__burger {
            position: absolute;
            right: 0;
            top: 12px;
            width: 28px;
            height: 3px;
            background-color: hsl(var(--clr-white));
            @include u.transition-ease-in-out;
    
            &::before {
                content: '';
                position: absolute;
                top: -8px;
                width: 28px;
                height: 3px;
                background-color: hsl(var(--clr-white));
                @include u.transition-ease-in-out;
            }
    
            &::after {
                content: '';
                position: absolute;
                top: 8px;
                width: 20px;
                height: 3px;
                background-color: hsl(var(--clr-white));
                @include u.transition-ease-in-out;
            }

            &.open {
                transform: rotate(720deg);
                background-color: transparent;

                &::before {
                    transform: rotate(45deg) translate(5px, 8px);
                }
    
                &::after {
                    width: 28px;
                    transform: rotate(-45deg) translate(3px, -7px);
                }
            }
        }
    }
}

.menu {
    margin-right: -1em;
    font-size: var(--fs-small);
    font-weight: var(--fw-medium);
    text-transform: uppercase;
    @include u.transition-ease-in-out;

    @include u.breakpoint-down(md) {
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: 20em;
        height: auto;
        overflow: hidden;
        background-color: hsla(var(--clr-black), .95);
        z-index: 20;
        transform: translateX(100%);

        &__list {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100vh;

            &-item {
                margin-left: 0;
                margin-bottom: 3em;
                @include u.transition-ease-in-out;
            }

            .link {
                display: inline-block;
                padding: .5em 1.25em;
                width: 8em;
                text-align: center;
            }
        }

        &.open {
            transform: translateX(0);

            .menu__list-item {
                animation: showItemsUp 500ms both;
            }

            @for $i from 1 through 7 {
                .menu__list-item:nth-child(#{$i}) {
                    animation-delay: ($i * 0.05s) + 0.15s;
                }
            }
        }

    }
}

.menu__list {
    display: flex;

    &-item {
        margin-left: 2em;
    }
    
    .link {
        position: relative;
        padding: .325em .25em;
        cursor: pointer;
    
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 0.1em;
            left: 0;
            bottom: 0;
            background-color: hsl(var(--clr-accent));
            transform: scale(0, 1);
            transform-origin: 0% 100%;
            @include u.transition-ease-in-out;
        }
    
        &:hover, &:focus {                
            &::after {
                transform: scale(1, 1);
            }
        }
    
        &.active {
            &::after {
                transform: scale(1, 1);
            }
        }
    }
}

