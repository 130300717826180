:root {  
    --clr-light: 0, 100%, 98%;
    --clr-dark: 212, 100%, 16%;
    --clr-primary: 188, 72%, 36%;
    --clr-accent: 72, 66%, 51%;
    --clr-black: 0, 0%, 0%;
    --clr-white: 0, 0%, 100%;
}

// --clr-light: 29, 30%, 70%;
// --clr-dark: 223, 18%, 15%;
// --clr-primary: 204, 37%, 19%;
// --clr-accent: 7, 72%, 54%;

// :root {
//     --ligth: #28c7fa;
//     --accent: #ff304f;
//     --primary: #002651;
// }

:root {
    --ligth: #fff5f5;
    --accent: #b2d430;
    --primary: #1a8b9d;
    --dark: #002651;
}

