@use '../utils/' as u;

.skills {

    .container {
        padding: 2em 0;
    } 

    .title {
        text-align: center;
        font-weight: var(--fw-medium);
        margin-top: 1em;
        @include u.animate-str(8);
    }

    .tabs__bloc {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-btn {
            background-color: transparent;
            border: none;
            text-align: center;
            width: 100%;
            padding: 1em 0;
            cursor: pointer;
            position: relative;

            .btn-text {
                margin-bottom: 0;
            }

            &:hover {
                background-color: hsl(var(--clr-white));
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                height: .25em;
                background-color: hsl(var(--clr-accent));
                transform: scale(0, 1);
                @include u.transition-ease-in-out;
            }

            &.active {
                background-color: hsl(var(--clr-white));

                &::after {
                    transform: scale(1, 1);
                }
            }
        }
    }

    .tabs__contents {
        background-color: white;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 4em 0;
    }

    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include u.transition-ease-in-out;
        margin-bottom: 2em;
        
        &.grow {
            animation: grow 1s both;
        }
        
        &.shrink {
            animation: shrink 1s forwards;
        }

        &__name {
            margin-bottom: 0;
        }

        &__brand-icon {
            height: 1em;
            width: 1em;
            font-size: 2em;
            color: hsl(1, 77%, 55%);
            background-color: hsla(var(--clr-black,), .5);
            padding: .25em;
            border-radius: 50%;
            margin-bottom: .25em;
        }
    }

    .item__rate {
        width: 80px;
        height: 80px;
        position: relative;

        .outer {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            box-shadow: 6px 6px 10px -1px hsl(var(--clr-black)),
                        -6px -6px 10px -1px hsl(var(--clr-white));
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .inner {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            box-shadow: inset 4px 4px 6px -1px hsl(var(--clr-black)),
                        inset -4px -4px 6px -1px hsl(var(--clr-white));
                        // -0.5px -0.5px 0px hsl(var(--clr-white)),
                        // 0.5px 0.5px 0px hsl(var(--clr-black)),
                        // 0px 12px 10px -10px hsl(var(--clr-black));
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-svg {
            position: absolute;
            top: 0;
            left: 0;

            circle {
                fill: none;
                stroke: hsl(var(--clr-accent));
                stroke-width: 10px;
                stroke-dasharray: 472;
                stroke-dashoffset: 472;
                animation: svgProgress 1s linear forwards;
            }
        }
    }
}